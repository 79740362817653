.pn_statue_icon {
  width: 0.65rem;
  height: 0.7rem;
  position: absolute;
  top: 0.05rem;
  right: 0.05rem;
}

.default_avatar {
  width: 0.43rem;
  height: 0.43rem;
  display: block;
  margin: 0 auto;
}

.default_avatar_wrap {
  text-align: center;
  margin-top: 0.15rem;
  width: 0.7rem;
  padding: 0 0.08rem;
}

.btn_mid_share {
  margin: 0.3rem auto 0;
  width: 2.5rem;
  background: #fe113b;
  height: 0.4rem;
  border-radius: 0.2rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kaituan_wrap {
  position: fixed;
  right: 0;
  bottom: 0.7rem;
}

.kaituan_wrap .kaituan {
  width: 0.75rem;
  height: 0.75rem;
}

.poster-shadow {
  border-radius: 0.1rem;
  width: 3rem;
  padding: 0.08rem 0.2rem;
  background: #fff;
  overflow: hidden;
}

.poster-shadow .title {
  text-align: center;
}

.poster-shadow .position {
  width: 1.2rem;
  height: 1.2rem;
}

.posterSrc {
  display: block;
  width: 3.2rem;
}